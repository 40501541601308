import React, { useState, useEffect } from "react"
import { Button, Container } from "react-bootstrap"
import { useStaticQuery, graphql, Link } from "gatsby"
import { loadStripe } from "@stripe/stripe-js"
import { clickEvent } from "../utils/events"

import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

function PaymentTable() {
  const [stripe, updateStripe] = useState({})
  const [errorMessage, updateErrorMessage] = useState("")

  const stripeQuery = useStaticQuery(graphql`
    query {
      allStripePrice(filter: { active: { eq: true } }) {
        nodes {
          active
          id
          product {
            id
          }
          billing_scheme
          unit_amount
          type
          nickname
          metadata {
            planType
            prepayPeriod
          }
        }
      }
      allStripeProduct {
        nodes {
          id
          name
        }
      }
    }
  `)

  const canvasCloudPrices = findCanvasCloudPrices(stripeQuery)

  async function redirectToPayment(priceId) {
    const { stripeError } = await stripe.redirectToCheckout({
      lineItems: [{ price: priceId, quantity: 1 }],
      mode: "payment",
      successUrl: `${process.env.GATSBY_BASE_URL}/canvas-cloud-purchased`,
      cancelUrl: `${process.env.GATSBY_BASE_URL}/canvas-payment`,
    })
    if (stripeError) {
      updateErrorMessage(
        "Sorry, we are having an issue. If this persists, please email us at support@gridfabric.zendesk.com"
      )
    }
  }

  useEffect(() => {
    async function implementStripe() {
      const stripeObj = await loadStripe(
        process.env.GATSBY_STRIPE_PUBLISHABLE_KEY
      )
      updateStripe(stripeObj)
    }
    implementStripe()
  }, [])

  return (
    <Layout>
      <SEO title="Canvas Payment" />
      <div className="page-header">
        <Container>
          <h1 style={{ padding: "40px" }}>Purchase Canvas Cloud</h1>
        </Container>
      </div>

      <Container style={{ maxWidth: "700px" }}>
        <div style={{ textAlign: "center", marginTop: "50px" }}>
          <p>
            With Canvas Cloud, you pay for what you use. Pre-pay for a plan
            below and we will follow up with your account login details. We also
            offer a 2 week free trial,{" "}
            <Link
              to="/about#contact"
              onClick={() =>
                clickEvent("Canvas Payment", "Canvas Payment free trial")
              }
            >
              contact us
            </Link>{" "}
            to find out more.
          </p>
          <div className="table-feature">
            <table>
              <tbody>
                {TableFeatureRow("Plan Type", "Test Plan", "Pilot Plan")}
                {TableFeatureRow("# Connected VENs", "Up to 2", "Up to 10*")}
                {TableFeatureRow("# User Accounts", "Up to 2", "Up to 5")}
                <tr>
                  <td
                    colspan="3"
                    style={{ textAlign: "center", backgroundColor: "#FAFAFA" }}
                  >
                    Select plan to go to payment
                  </td>
                </tr>
                {PriceRow(canvasCloudPrices, "3 months", redirectToPayment)}
                {PriceRow(canvasCloudPrices, "6 months", redirectToPayment)}
                {PriceRow(canvasCloudPrices, "12 months", redirectToPayment)}
              </tbody>
            </table>
          </div>
          <p>
            * if you need more than 10 connected VENs, please{" "}
            <Link
              to="/about#contact"
              onClick={() =>
                clickEvent("Canvas Payment", "Canvas Payment more VENs")
              }
            >
              contact us
            </Link>
          </p>
          <div style={{ textAlign: "center" }}>
            {errorMessage ? (
              <p
                style={{
                  fontSize: "0.8em",
                  marginTop: "10px",
                  color: "tomato",
                }}
              >
                {errorMessage}
              </p>
            ) : null}
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export default PaymentTable

function findCanvasCloudId(productList) {
  const canvasCloudId = productList.nodes.find(
    ({ name }) => name === "Canvas Cloud"
  ).id
  if (!canvasCloudId)
    throw new Error(
      "Sorry, there was an error. Please report to support@gridfabric.zendesk.com"
    )
  return canvasCloudId
}

function findCanvasCloudPrices({ allStripeProduct, allStripePrice }) {
  const canvasCloudID = findCanvasCloudId(allStripeProduct)

  const canvasCloudPriceList = allStripePrice.nodes.filter(
    ({ product }) => product.id === canvasCloudID
  )

  return canvasCloudPriceList.map(priceItem => {
    return {
      id: priceItem.id,
      planType: priceItem.metadata.planType,
      prepayPeriod: priceItem.metadata.prepayPeriod,
      price: priceItem.unit_amount / 100,
    }
  })
}

function PriceButton(canvasCloudPrices, prepayPeriod, planType, goToPayment) {
  const priceItem = canvasCloudPrices.find(priceItem => {
    return (
      priceItem.planType === planType && priceItem.prepayPeriod === prepayPeriod
    )
  })
  return (
    <div style={{textAlign: "center"}}>
      <div style={{display: "inline-block", width: "70px" }}>${priceItem.price}</div>

      <Button
        className="btn-gridfabric"
        size="sm"
        style={{ margin: "auto" }}
        onClick={() => goToPayment(priceItem.id)}
      >
        Go
      </Button>
    </div>
  )
}

function PriceRow(canvasCloudPrices, prepayPeriod, goToPayment){
  return (
    <tr>
      <th className="table-header">{prepayPeriod} prepaid</th>
      <td>
        {PriceButton(canvasCloudPrices, prepayPeriod, "Test", goToPayment)}
      </td>
      <td>
        {PriceButton(canvasCloudPrices, prepayPeriod, "Pilot", goToPayment)}
      </td>
    </tr>
  )
}

function TableFeatureRow(title, firstCell, secondCell){
  return (
    <tr>
      <th className="table-header">{title}</th>
      <td style={{ textAlign: "center" }}>{firstCell}</td>
      <td style={{ textAlign: "center" }}>{secondCell}</td>
    </tr>
  )
}